import React from 'react';

export const routes = [
    // English Routes
    {
        path: '/en',
        name: 'About Us',
        Component: React.lazy(() => import('../pages/Home/Home')),
    },
    {
        path: '/en/products',
        name: 'Products',
        Component: React.lazy(() => import('../pages/Catalogue/Catalogue')),
    },
    {
        path: '/en/contact',
        name: 'Contact',
        Component: React.lazy(() => import('../pages/Contact/Contact')),
    },
    {
        path: '/en/product/:slug',
        name: 'Product',
        Component: React.lazy(() => import('../pages/Product/Product')),
    },
    {
        path: '/en/product/:slug/customize/samples',
        name: 'Customizer',
        Component: React.lazy(() => import('../pages/Customizer/Customizer')),
    },
    {
        path: '/en/product/:slug/customize/offer',
        name: 'Customizer',
        Component: React.lazy(() => import('../pages/Customizer/Customizer')),
    },
    
    // Polish Routes
    {
        path: '/pl',
        name: 'O Nas',
        Component: React.lazy(() => import('../pages/Home/Home')),
    },
    {
        path: '/pl/produkty',
        name: 'Produkty',
        Component: React.lazy(() => import('../pages/Catalogue/Catalogue')),
    },
    {
        path: '/pl/kontakt',
        name: 'Kontakt',
        Component: React.lazy(() => import('../pages/Contact/Contact')),
    },
    {
        path: '/pl/produkt/:slug',
        name: 'Produkt',
        Component: React.lazy(() => import('../pages/Product/Product')),
    },
    {
        path: '/pl/produkt/:slug/konfiguracja/probki',
        name: 'Konfigurator',
        Component: React.lazy(() => import('../pages/Customizer/Customizer')),
    },
    {
        path: '/pl/produkt/:slug/konfiguracja/oferta',
        name: 'Konfigurator',
        Component: React.lazy(() => import('../pages/Customizer/Customizer')),
    },

    // Turkish Routes
    {
        path: '/tr',
        name: 'Hakkımızda',
        Component: React.lazy(() => import('../pages/Home/Home')),
    },
    {
        path: '/tr/urunler',
        name: 'Ürünler',
        Component: React.lazy(() => import('../pages/Catalogue/Catalogue')),
    },
    {
        path: '/tr/iletisim',
        name: 'İletişim',
        Component: React.lazy(() => import('../pages/Contact/Contact')),
    },
    {
        path: '/tr/urun/:slug',
        name: 'Ürün',
        Component: React.lazy(() => import('../pages/Product/Product')),
    },
    {
        path: '/tr/urun/:slug/ozellestirme/ornekler',
        name: 'Özelleştirici',
        Component: React.lazy(() => import('../pages/Customizer/Customizer')),
    },
    {
        path: '/tr/urun/:slug/ozellestirme/teklif',
        name: 'Özelleştirici',
        Component: React.lazy(() => import('../pages/Customizer/Customizer')),
    },
    // Uncomment and modify the NoPage route if needed
    // {
    //     path: '/*',
    //     name: 'NoPage',
    //     Component: React.lazy(() => import('../pages/NoPage/NoPage')),
    // },
];